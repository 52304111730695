import React from 'react';
import {
    List,
    Datagrid,
    SelectInput,
    TextField,
    DateField,
    TextInput,
    Filter,
    ReferenceField,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin, isAdmin } from '../utils';

const ManagersContractsFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id договора менеджера' },
                { id: 'manager_id', name: 'Id менеджера' },
                { id: 'com_cond', name: 'Комиссия' },
                { id: 'start_date', name: 'Дата заключения договора' },
                { id: 'end_date', name: 'Дата расторжения договора' },
                { id: 'details', name: 'Подробности' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ManagerContractsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ManagersContractsFilter />}
        bulkActionButtons={isAdmin(permissions) ? <BulkDeleteButtonAdmin permissions={permissions}/> : false}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id договора менеджера"
                source="id"
            />
            <ReferenceField
                label="Имя менеджера"
                source="manager_id"
                reference="managers"
                linkType={false}
                allowEmpty
            >
                <TextField source="name" />
            </ReferenceField>
            <TextField
                label="Комиссия"
                source="com_cond"
            />
            <DateField
                label="Дата заключения договора"
                source="start_date"
                locales="ru-RU"
            />
            <DateField
                label="Дата расторжения договора"
                source="end_date"
                locales="ru-RU"
            />
            <TextField
                label="Подробности"
                source="details"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
