import React from 'react';
import {
    ReferenceInput,
    SelectInput,
    SimpleForm,
    Create,
    TextInput,
    required,
    number,
    minValue,
    maxValue,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';


export const AddManagerContract = props => (
  <Create {...props}>
      <SimpleForm>
          <ReferenceInput
            perPage={10000}
            label="Имя менеджера"
            source="manager_id"
            reference="managers"
            validate={[required()]}
          >
            <SelectInput optionText="name" />
          </ReferenceInput>
          <TextInput
            label="Комиссия"
            source="com_cond"
            validate={[required(), number(), minValue(0), maxValue(1)]}
          />
          <DateInput
              label="Дата заключения договора"
              source="start_date"
              options={{ format: 'dd.MM.YYYY' }}
              validate={[required()]}
          />
          <DateInput
              label="Дата расторжения договора"
              source="end_date"
              options={{ format: 'dd.MM.YYYY' }}
          />
          <TextInput
              label="Подробности"
              source="details"
          />
      </SimpleForm>
  </Create>
);
