
import React from 'react';
import {
    SelectInput,
    SimpleForm,
    Edit,
    TextInput,
    required,
} from 'react-admin';
import { ToolbarSaveAllDeleteAdmin } from '../utils';


export const EditManager = ({ permissions, ...props }) => (
  <Edit {...props}>
      <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
          <TextInput
              disabled
              label="Id менеджера"
              source="id"
          />
          <TextInput
              label="Имя менеджера"
              source="name"
              validate={[required()]}
          />
          <SelectInput
              label="Статус менеджера"
              source="status"
              choices={[
                  { id: 'active', name: 'active' },
                  { id: 'inactive', name: 'inactive' },
              ]}
              validate={[required()]}
          />
          <TextInput
              label="Подробности"
              source="details"
          />
          <TextInput
              label="Контакты"
              source="contact"
          />
          <TextInput
              disabled
              label="Время создания"
              source="created"
          />
          <TextInput
              disabled
              label="Последнее обновление"
              source="updated"
          />
      </SimpleForm>
  </Edit>
);
