
import React from 'react';
import {
    Edit,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    number,
    minValue,
    maxValue
} from 'react-admin';
import { ToolbarSaveAllDeleteAdmin } from '../utils';
import { DateInput } from 'react-admin-date-inputs';

export const EditManagerContract = ({ permissions, ...props }) => (
  <Edit {...props}>
      <SimpleForm toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
         <TextInput
            disabled
            label="Id менеджера"
            source="id"
         />
          <ReferenceInput
            perPage={10000}
            label="Имя менеджера"
            source="manager_id"
            reference="managers"
            required
          >
             <SelectInput optionText={m => `(${m.id}) ${m.name}`} />
          </ReferenceInput>
          <TextInput
            label="Комиссия"
            source="com_cond"
            validate={[required(), number(), minValue(0), maxValue(1)]}
          />
          <DateInput
              label="Дата заключения договора"
              source="start_date"
              options={{ format: 'dd.MM.YYYY' }}
              validate={[required()]}
          />
          <DateInput
              label="Дата расторжения договора"
              source="end_date"
              options={{ format: 'dd.MM.YYYY' }}
              require={false}
          />
          <TextInput
              label="Подробности"
              source="details"
          />
          <TextInput
            disabled
            label="Время создания"
            source="created"
          />
          <TextInput
            disabled
            label="Последнее обновление"
            source="updated"
          />
      </SimpleForm>
  </Edit>
);
