import React from 'react';
import { Button, crudUpdateMany } from 'react-admin';
import moment from 'moment/moment';
import config from '../config.json';
import ActIcon from '@material-ui/icons/Receipt';
import { connect } from 'react-redux';
import FileSaver from 'file-saver';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

const ActButton = props => {
    const handleClick = () => {
        const { resource, filterValues, selectedIds } = props;
        const startDate = moment(filterValues.start_date);
        const endDate = moment(filterValues.end_date);
        const body = { ids: selectedIds, startDate, endDate };
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const permissions = localStorage.getItem('permissions');
        let url;
        if (permissions === 'franchisee') {
            url = `${URL}franchisee/${resource}/act`;
        } else if (permissions === 'partner') {
            url = `${URL}partner/${resource}/act`;
        } else if (permissions === 'agent') {
            url = `${URL}agent/${resource}/act`;
        } else if (permissions === 'partner_service') {
            url = `${URL}partner_service/${resource}/act`;
        } else {
            url = `${URL}admin/${resource}/act`;
        }

        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then(response => {
                if (response.status === 200) {
                    response.arrayBuffer().then(blob => {
                        var q = new Blob([blob], { type: 'application/zip' });
                        FileSaver.saveAs(q, 'reports.zip');
                    });
                } else throw new Error(`Unsupported fetch action type `);
            })
            .catch(e => {
                console.log(e);
            });
    };

    return (
        <Button
            label="акт"
            onClick={handleClick}
        >
            <ActIcon />
        </Button>
    );
};

export default connect(undefined, { crudUpdateMany })(ActButton);
