import React from 'react';
import { BooleanField, Datagrid, DateField, List, SelectField, TextField } from 'react-admin';
import {
    BulkDeleteButtonAdmin,
    CustomPagination,
    isAdmin,
    isEmployee,
    isFranchisee,
    isPartner,
    isPartnerService,
} from '../utils';
import { countryOptions } from '../constants/countries';
import TimeField from '../TimeField';
import { tariffOptionsLocations } from '../constants/tariffs';
import LocationsFilter from './Filter';

const ListLocations = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<LocationsFilter permissions={permissions} />}
        bulkActionButtons={!isPartner(permissions) ? <BulkDeleteButtonAdmin permissions={permissions}/> : false}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick={isPartner(permissions) ? 'show' : 'edit'}>
            <TextField
                label="Id локации"
                source="id"
            />
            {isFranchisee(permissions) && (
                <TextField
                    label="Партнер"
                    source="partner_name"
                />
            )}
            {!isPartnerService(permissions) && (
                <TextField
                    label="Id договора"
                    source="contract_id"
                />
            )}
            {isEmployee(permissions) && (
                <TextField
                    label="Id договора менеджера"
                    source="manager_contract_id"
                />
            )}
            {(isAdmin(permissions) || isPartnerService(permissions)) && (
                <TextField
                    label="Сервисный партнер"
                    source="partner_service_name"
                />
            )}
            {(isAdmin(permissions) || isPartnerService(permissions)) && (
                <TextField
                    label="Id договора сервисного партнера"
                    source="partner_service_contract_id"
                />
            )}
            {(!isPartner(permissions)) && (
                <BooleanField
                    label="Депозит"
                    source="deposit_feature"
                />                
            )}
            <TextField
                label="Название"
                source="name"
            />
            <TextField
                label="Адрес"
                source="address"
            />
            <SelectField
                label="Страна"
                source="country"
                choices={countryOptions}
            />
            <TextField
                label="Город"
                source="city"
            />
            <TextField
                label="Станция"
                source="sno"
            />
            {(!isPartner(permissions)) && (
                <TextField
                    label="Префикс"
                    source="holder_prefix"
                />                
            )}
            <TextField
                label="Короткий номер"
                source="holder_short_number"
            />
            <TextField
                label="Категория"
                source="category"
            />
            <TextField
                label="Тип"
                source="type"
            />
            <TextField
                label="Широта"
                source="latitude"
            />
            <TextField
                label="Долгота"
                source="longitude"
            />
            <TimeField
                label="Начало работы"
                source="start_daytime"
            />
            <TimeField
                label="Конец работы"
                source="end_daytime"
            />
            <TextField
                label="Время работы"
                source="work_duration"
            />
            <TextField
                label={'Временная зона'}
                source={'timezone'}
            />
            <TextField
                label="Гайд"
                source="guide"
            />
            {(!isPartner(permissions)) && (
                <TextField
                    label="Комментарии"
                    source="comment"
                />
            )}
            <TextField
                label="Статус"
                source="status"
            />
            <TextField
                label="Видимость на карте"
                source="visibility"
            />
            {(!isPartner(permissions)) && (
                <SelectField
                    label="Опция тарифа"
                    source="tariff_option"
                    choices={tariffOptionsLocations}
                    allowEmpty
                />
            )}
            {(!isPartner(permissions)) && (
                <SelectField
                    label="Опция тарифа первой сессии"
                    source="tariff_option_first_session"
                    choices={tariffOptionsLocations}
                    allowEmpty
                />
            )}
            {(!isPartner(permissions)) && (
                <DateField
                    label="Бонус с"
                    source="bonus_start_date"
                    locales="ru-RU"
                />
            )}
            {(!isPartner(permissions)) && (
                <DateField
                    label="Время создания"
                    source="created"
                    showTime
                    locales="ru-RU"
                />
            )}
            {(!isPartner(permissions)) && (
                <DateField
                    label="Последнее обновление"
                    source="updated"
                    showTime
                    locales="ru-RU"
                />                
            )}
        </Datagrid>
    </List>
);

export default ListLocations;
