import {
    ArrayField,
    BooleanField,
    Datagrid,
    DateField,
    FunctionField,
    ImageField,
    ReferenceField,
    SelectField,
    number,
    Show,
    SimpleShowLayout,
    TextField,
    SimpleForm,
    FormDataConsumer,
    ReferenceInput,
    SelectInput,
} from 'react-admin';
import {
    isAdmin,
    isEmployee,
    isPartner,
    isFranchisee,
    isGeEmployee,
    isPartnerService,
    ToolbarSaveAllDeleteAdmin,
} from '../utils';
import { LocalizedDataInput } from '../localizedDataInput/LocalizedDataInput';
import { countryOptions } from '../constants/countries';
import { tariffOptionsLocations } from '../constants/tariffs';
import React from 'react';
import { optionRenderer, managerOptionRenderer } from './utils';
import { ShowScheduleField, TypeField } from './components';

const ShowLocation = props => {
    const { permissions, ...rest } = props;
    return (
        <Show {...rest}>
            <SimpleShowLayout toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}>
                <SimpleForm>
                    <TextField
                        label="Id локации"
                        source="id"
                    />
                    {!isPartnerService(permissions) && (
                        <ReferenceField
                            label="Id договора"
                            source="contract_id"
                            reference="partner_contracts"
                            linkType={false}
                            allowEmpty
                        >
                            <FunctionField render={optionRenderer} />
                        </ReferenceField>
                    )}
                    {(isAdmin(permissions) || isPartnerService(permissions)) && (
                        <ReferenceField
                            label="Id договора c сервисным партнером"
                            source="partner_service_contract_id"
                            reference={
                                isPartnerService(permissions)
                                    ? 'partner_contracts'
                                    : 'partner_service_contracts'
                            }
                            linkType={false}
                            allowEmpty
                        >
                            <FunctionField render={optionRenderer} />
                        </ReferenceField>
                    )}
                    {(isEmployee(permissions) || isPartner(permissions)) && (
                        <FormDataConsumer>
                            {({ formData, ...rest }) => {
                                return (formData.manager_contract_id) && (
                                    <ReferenceInput
                                        key={formData.name}
                                        label="Id договора c менеджером"
                                        source="manager_contract_id"
                                        reference="manager_contracts"
                                        filter={{ manager: true }}
                                        disabled
                                        parse={value => value || null}
                                    >
                                        <SelectInput optionText={data => managerOptionRenderer(data)} />
                                     </ReferenceInput>
                                )}
                            }
                        </FormDataConsumer>
                    )}
                    {(!isPartner(permissions)) && (
                        <BooleanField
                            label="Депозит"
                            source="deposit_feature"
                        />  
                    )}
                    <LocalizedDataInput
                        label="Название"
                        source="name_localized"
                    />
                    <LocalizedDataInput
                        label="Адрес"
                        source="address_localized"
                    />
                    <SelectField
                        label="Страна"
                        source="country"
                        choices={countryOptions}
                    />
                    <LocalizedDataInput
                        label="Город"
                        source="city_localized"
                    />
                    <SelectField
                        label="Категория"
                        source="category"
                        choices={[
                            { id: 'Общепит', name: 'Общепит' },
                            { id: 'Путешествия', name: 'Путешествия' },
                            { id: 'Спорт', name: 'Спорт' },
                            { id: 'Красота и здоровье', name: 'Красота и здоровье' },
                            { id: 'Развлечения и досуг', name: 'Развлечения и досуг' },
                            { id: 'Транспорт', name: 'Транспорт' },
                            { id: 'Разное', name: 'Разное' },
                            { id: 'Другое', name: 'Другое' },
                        ]}
                        allowEmpty
                    />
                    {isPartner(permissions) ? (
                        <TextField
                            label="Тип"
                            source="type"
                        />
                    ) : (
                        <TypeField
                            label="Тип"
                            source="type"
                        />
                    )}
                    <TextField
                        label="Широта"
                        source="latitude"
                    />
                    <TextField
                        label="Долгота"
                        source="longitude"
                    />
                    <TextField
                        label="Временная зона"
                        source="timezone"
                        disabled
                    />
                    <TextField
                        label="Начало работы"
                        source="start_daytime"
                        disabled
                    />
                    <TextField
                        label="Конец работы"
                        source="end_daytime"
                    />
                    <TextField
                        label="Время работы"
                        source="work_duration"
                        validate={number()}
                        disabled
                    />
                    <FunctionField
                        style={{ width: '100%' }}
                        label={null}
                        source={'schedule'}
                        render={record => {
                            return (
                                <>
                                    <ShowScheduleField
                                        schedule={record.schedule}
                                        timezone={record.timezone}
                                    />
                                </>
                            );
                        }}
                    />

                    <LocalizedDataInput
                        label="Гайд"
                        source="guide_localized"
                    />
                    {(!isPartner(permissions)) && (
                        <TextField
                            label="Комментарии"
                            source="comment"
                        />                        
                    )}
                    <SelectField
                        label="Статус"
                        source="status"
                        choices={[
                            { id: 'active', name: 'active' },
                            { id: 'inactive', name: 'inactive' },
                        ]}
                    />
                    <SelectField
                        label="Видимость на карте"
                        source="visibility"
                        choices={[
                            { id: 'visible', name: 'visible' },
                            { id: 'hidden', name: 'hidden' },
                        ]}
                    />
                    {(!isPartner(permissions)) && (
                        <SelectField
                            label="Опция тарифа"
                            source="tariff_option"
                            choices={tariffOptionsLocations}
                            allowEmpty
                        />
                    )}
                    {(!isPartner(permissions)) && (
                        <SelectField
                            label="Опция тарифа первой сессии"
                            source="tariff_option_first_session"
                            choices={tariffOptionsLocations}
                            allowEmpty
                        />
                    )}
                    {isFranchisee(permissions) && (
                        <DateField
                            label="Бонус с"
                            source="bonus_start_date"
                            options={{ format: 'dd.MM.YYYY' }}
                        />
                    )}
                    {isGeEmployee(permissions) && (
                        <ArrayField
                            label="Внешние промоакции"
                            source="promos"
                        >
                            <Datagrid
                                rowClick={recStr => {
                                    const rec = JSON.parse(recStr);
                                    return `/external_promo/${rec.promo_id}/show`;
                                }}
                            >
                                >
                                <TextField
                                    label="Id"
                                    source="promo_id"
                                />
                                <DateField
                                    label="Время добавления"
                                    source="created"
                                    showTime
                                    locales="ru-RU"
                                />
                            </Datagrid>
                        </ArrayField>
                    )}
                    <ImageField
                        source="photo"
                        label="Фото"
                        src="src"
                        title="name"
                    />
                </SimpleForm>
            </SimpleShowLayout>
        </Show>
    );
};

export default ShowLocation;
