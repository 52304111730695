import React from 'react';
import {
    List,
    Datagrid,
    SelectInput,
    TextField,
    DateField,
    TextInput,
    Filter,
} from 'react-admin';
import { CustomPagination, BulkDeleteButtonAdmin, isAdmin } from '../utils';

const ManagersFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id менеджера' },
                { id: 'name', name: 'Имя менеджера' },
                { id: 'status', name: 'Статус менеджера' },
                { id: 'contact', name: 'Контакты' },
                { id: 'details', name: 'Подробности' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ManagersList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ManagersFilter />}
        bulkActionButtons={isAdmin(permissions) ? <BulkDeleteButtonAdmin permissions={permissions}/> : false}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id менеджера"
                source="id"
            />
            <TextField
                label="Имя менеджера"
                source="name"
            />
            <TextField
                label="Статус менеджера"
                source="status"
            />
            <TextField
                label="Подробности"
                source="details"
            />
            <TextField
                label="Контакты"
                source="contact"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);
