export const countryOptions = [
    { id: 'ru', name: 'Россия' },
    { id: 'kz', name: 'Казахстан' },
    { id: 'az', name: 'Азербайджан' },
    { id: 'uz', name: 'Узбекистан' },
    { id: 'am', name: 'Армения' },
    { id: 'br', name: 'Brazil' },
    { id: 'ae', name: 'UAE' },
    { id: 'il', name: 'Israel' },
];
