import React, { Component, Fragment } from 'react';
import { Datagrid, Filter, List, SelectInput, TextField, TextInput } from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import moment from 'moment';
import ExportButton from './reports/ExportButton';
import SendButton from './reports/SendButton';
import ActButton from './reports/ActButton';
import { CustomPagination, isEmployee, isPartner } from './utils';
import config from './config';

const env = process.env.REACT_APP_CUSTOM_NODE_ENV;

const PartnerReportsFilter = props => (
    <Filter {...props}>
        <DateInput
            label="C"
            source="start_date"
            options={{ format: 'dd.MM.YYYY' }}
            isRequired={false}
            alwaysOn
        />
        <DateInput
            label="До"
            source="end_date"
            options={{ format: 'dd.MM.YYYY' }}
            isRequired={false}
            alwaysOn
        />
        {isEmployee(props.permissions) ? (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id' },
                    { id: 'name', name: 'Имя партнера' },
                    { id: 'franchisee', name: 'Франчайзи' },
                ]}
                alwaysOn
            />
        ) : !isPartner(props.permissions) ? (
            <SelectInput
                label="Поле"
                source="field"
                choices={[
                    { id: 'id', name: 'Id' },
                    { id: 'name', name: 'Имя партнера' },
                ]}
                alwaysOn
            />
        ) : null}
        {!isPartner(props.permissions) && (
            <TextInput
                label="Поиск"
                source="q"
                alwaysOn
            />
        )}
        {!isPartner(props.permissions) && (
            <SelectInput
                label="НДС"
                source="vat"
                choices={[
                    { id: 'yes', name: 'Да' },
                    { id: 'no', name: 'Нет' },
                ]}
            />
        )}
    </Filter>
);

class BulkActionButtons extends Component {
    state = {
        allowActButton: false,
        loading: true,
    };

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        const { selectedIds } = this.props;
        const URL = config[env].url;
        const token = localStorage.getItem('token');
        const permissions = localStorage.getItem('permissions');

        let url;
        if (permissions === 'franchisee') {
            url = `${URL}franchisee/franchisee/allow_act_button`;
        } else {
            this.setState({ allowActButton: true, loading: false })
            return;
        }

        fetch(url, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
            method: 'POST',
            body: JSON.stringify({ ids: selectedIds }), 
        })
        .then(response => {
            if (response.status === 200) {
                this.setState({ allowActButton: true, loading: false });
                return;
            }
            this.setState({ allowActButton: false, loading: false });
        })
        .catch(e => {
            this.setState({ allowActButton: false, loading: false });
        });
    };

    render() {
        const { allowActButton, loading } = this.state;

        if (loading) return <div>Загрузка...</div>;

        return (
            <Fragment>
                <ExportButton
                    label="Экспортировать"
                    {...this.props}
                />
                {allowActButton && 
                    (
                        <ActButton
                        label="Акт"
                        {...this.props}
                    />
                    )
                }
                <SendButton
                    label="Отправить"
                    {...this.props}
                />
            </Fragment>
        )
    }
}


export const PartnerReportsList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<PartnerReportsFilter permissions={permissions} />}
        // pagination={false}
        pagination={<CustomPagination />}
        exporter={false}
        filterDefaultValues={{
            start_date: moment().startOf('month').toDate(),
            end_date: moment().startOf('day').toDate(),
        }}
        bulkActionButtons={<BulkActionButtons permissions={permissions}/>}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id партнера"
                source="id"
            />
            <TextField
                label="Имя партнера"
                source="name"
            />
            <TextField
                label="Выручка"
                source="revenue"
            />
            <TextField
                label="Комиссия"
                source="commission"
            />
            <TextField
                label="НДС"
                source="vat_payer"
            />
            {isEmployee(permissions) && (
                <TextField
                    label="Франчайзи"
                    source="franchisee"
                />
            )}
        </Datagrid>
    </List>
);
