import React from 'react';
import {
    ArrayField,
    ArrayInput,
    AutocompleteInput,
    BooleanField,
    BooleanInput,
    ChipField,
    Create,
    Datagrid,
    DateField,
    Edit,
    Filter,
    ImageField,
    ImageInput,
    List,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    SimpleForm,
    SimpleFormIterator,
    SimpleShowLayout,
    SingleFieldList,
    TextField,
    TextInput,
} from 'react-admin';
import { BulkDeleteButtonAdmin, CustomPagination, ToolbarSaveAllDeleteAdmin } from './utils';
import { DateTimeInput } from 'react-admin-date-inputs';

const filterToQuery = searchText => ({ name_ilike: `%${searchText}%` });

const ExternalPromoFilter = props => (
    <Filter {...props}>
        <SelectInput
            label="Поле"
            source="field"
            choices={[
                { id: 'id', name: 'Id промо' },
                { id: 'location_id', name: 'Id локации' },
                { id: 'name', name: 'Наименование' },
                { id: 'enabled', name: 'Активно' },
                { id: 'promo_code', name: 'Промокод' },
                { id: 'start_date', name: 'Время начала' },
                { id: 'end_date', name: 'Время завершения' },
                { id: 'created', name: 'Время создания' },
                { id: 'updated', name: 'Последнее обновление' },
            ]}
            alwaysOn
        />
        <TextInput
            label="Поиск"
            source="q"
            alwaysOn
        />
    </Filter>
);

export const ExternalPromoList = ({ permissions, ...props }) => (
    <List
        {...props}
        filters={<ExternalPromoFilter />}
        bulkActionButtons={<BulkDeleteButtonAdmin permissions={permissions} />}
        pagination={<CustomPagination />}
    >
        <Datagrid rowClick="edit">
            <TextField
                label="Id промо"
                source="id"
            />
            <TextField
                label="Наименование"
                source="name"
            />
            <BooleanField
                label="Активно"
                source="enabled"
            />
            <TextField
                label="Промокод"
                source="promo_code"
            />
            <DateField
                label="Время начала"
                source="start_date"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время завершения"
                source="end_date"
                showTime
                locales="ru-RU"
            />
            <ArrayField
                label="Локации"
                source="locations"
                sortable={false}
            >
                <SingleFieldList linkType={false}>
                    <ChipField
                        clickable={false}
                        source="location_id"
                    />
                </SingleFieldList>
            </ArrayField>
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </Datagrid>
    </List>
);

export const AddExternalPromo = ({ permissions, ...props }) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                label="Наименование"
                source="name"
                validate={[required()]}
            />
            <BooleanInput
                label="Активно"
                source="enabled"
            />
            <TextInput
                label="Промокод"
                source="promo_code"
                parse={value => value}
            />
            <TextInput
                label="QR код"
                source="qr_code"
                parse={value => value}
            />
            <TextInput
                label="Описание"
                source="description"
                validate={[required()]}
            />
            <TextInput
                label="Условия"
                source="terms"
                parse={value => value}
            />
            <TextInput
                label="Юр. данные"
                source="legal_info"
                parse={value => value}
            />
            <DateTimeInput
                label="Время начала"
                source="start_date"
                options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
            />
            <DateTimeInput
                label="Время завершения"
                source="end_date"
                options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
            />
            <ArrayInput
                label="Локации"
                source="locations"
            >
                <SimpleFormIterator>
                    <ReferenceInput
                        label="Локации"
                        source="location_id"
                        reference="locations"
                        page={1}
                        filterToQuery={searchText => ({ field: 'start_id', q: searchText })}
                        perPage={25}
                        sort={{ field: 'id', order: 'ASC' }}
                        allowEmpty
                    >
                        <AutocompleteInput optionText={choice => `${choice.id} (${choice.name})`} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ImageInput
                source="icon"
                label="Лого"
                accept="image/*"
                multiple={false}
            >
                <ImageField
                    source="src"
                    title="name"
                />
            </ImageInput>
            <ImageInput
                source="picture"
                label="Баннер"
                accept="image/*"
                multiple={false}
            >
                <ImageField
                    source="src"
                    title="name"
                />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export const EditExternalPromo = ({ permissions, ...props }) => (
    <Edit {...props}>
        <SimpleForm
            submitOnEnter={false}
            toolbar={<ToolbarSaveAllDeleteAdmin permissions={permissions} />}
        >
            <TextInput
                disabled
                label="Id заказа"
                source="id"
            />
            <TextInput
                label="Наименование"
                source="name"
                validate={[required()]}
            />
            <BooleanInput
                label="Активно"
                source="enabled"
                validate={[required()]}
            />
            <TextInput
                label="Промокод"
                source="promo_code"
                parse={value => value}
            />
            <TextInput
                label="QR код"
                source="qr_code"
                parse={value => value}
            />
            <TextInput
                label="Описание"
                source="description"
                validate={[required()]}
            />
            <TextInput
                label="Условия"
                source="terms"
                parse={value => value}
            />
            <TextInput
                label="Юр. данные"
                source="legal_info"
                parse={value => value}
            />
            <DateTimeInput
                label="Время начала"
                source="start_date"
                options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
            />
            <DateTimeInput
                label="Время завершения"
                source="end_date"
                options={{ format: 'dd.MM.YYYY HH:mm', ampm: false }}
            />
            <ArrayInput
                label="Локации"
                source="locations"
            >
                <SimpleFormIterator>
                    <ReferenceInput
                        label="Локации"
                        source="location_id"
                        reference="locations"
                        page={1}
                        filterToQuery={searchText => ({ field: 'start_id', q: searchText })}
                        perPage={25}
                        sort={{ field: 'id', order: 'ASC' }}
                        allowEmpty
                    >
                        <AutocompleteInput optionText={choice => `${choice.id} (${choice.name})`} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
            <ImageInput
                source="icon"
                label="Лого"
                accept="image/*"
                multiple={false}
            >
                <ImageField
                    source="src"
                    title="name"
                />
            </ImageInput>
            <ImageInput
                source="picture"
                label="Баннер"
                accept="image/*"
                multiple={false}
            >
                <ImageField
                    source="src"
                    title="name"
                />
            </ImageInput>
            <TextInput
                disabled
                label="Время создания"
                source="created"
            />
            <TextInput
                disabled
                label="Последнее обновление"
                source="updated"
            />
        </SimpleForm>
    </Edit>
);
export const ShowExternalPromo = ({ permissions, ...props }) => (
    <Show
        {...props}
        submitOnEnter={false}
    >
        <SimpleShowLayout>
            <TextField
                label="Id промо"
                source="id"
            />
            <TextField
                label="Наименование"
                source="name"
            />
            <BooleanField
                label="Активно"
                source="enabled"
            />
            <TextField
                label="Промокод"
                source="promo_code"
            />
            <TextField
                label="QR код"
                source="promo_code"
            />
            <TextField
                label="Описание"
                source="description"
            />
            <TextField
                label="Условия"
                source="terms"
            />
            <TextField
                label="Юр. данные"
                source="legal_info"
            />
            <DateField
                label="Время начала"
                source="start_date"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Время завершения"
                source="end_date"
                showTime
                locales="ru-RU"
            />
            <ArrayField
                label="Локации"
                source="locations"
            >
                <Datagrid
                    rowClick={recStr => {
                        const rec = JSON.parse(recStr);
                        return `/locations/${rec.location_id}/show`;
                    }}
                >
                    >
                    <TextField
                        label="Id"
                        source="location_id"
                    />
                    <DateField
                        label="Время добавления"
                        source="created"
                        showTime
                        locales="ru-RU"
                    />
                </Datagrid>
            </ArrayField>
            <ImageField
                source="icon"
                label="Лого"
                src="src"
                title="name"
            />
            <ImageField
                source="picture"
                label="Баннер"
                src="src"
                title="name"
            />
            <DateField
                label="Время создания"
                source="created"
                showTime
                locales="ru-RU"
            />
            <DateField
                label="Последнее обновление"
                source="updated"
                showTime
                locales="ru-RU"
            />
        </SimpleShowLayout>
    </Show>
);
